import AuthService from "../services/auth.service";
import { authApi } from "@/services/api";

const user = JSON?.parse(localStorage?.getItem("user"));

const commonValues = {
  currentUser: {},
};

const initialState = {
  ...commonValues,
  ...(user
    ? { status: { loggedIn: true }, user }
    : { status: { loggedIn: false }, user: null }),
};

export const auth = {
  namespaced: true,
  state: initialState,

  getters: {
    getCurrentUser(state) {
      return state.currentUser;


    },
  },
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        (user) => {
          commit("loginSuccess", user);
          return Promise.resolve(user);
        },
        (error) => {
          commit("loginFailure");
          return Promise.reject(error);
        },
      );
    },
    async getKysStatus({ commit }, userId) {
      try {
        const response = await authApi(`/Kyc/get-kyc-status?userId=${userId}`)
        console.log('response get kyc status', response)
        return await response.data.status
        
      } catch (err) {
        console.log(err)
      }
    },
    logout({ commit }) {
      AuthService.logout();
      commit("logout");
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        (response) => {
          commit("registerSuccess");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("registerFailure");
          return Promise.reject(error);
        },
      );
    },
    userUpdate({ commit }, user) {
      return AuthService.userUpdate(user).then(
        (response) => {
          commit("updateSuccess");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("updateFailure");
          return Promise.reject(error);
        },
      );
    },
    documentsUpload({ commit }, formData) {
      return AuthService.documentsUpload(formData).then(
        (response) => {
          commit("uploadSuccess");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("uploadFailure");
          return Promise.reject(error);
        },
      );
    },
    refreshToken({ commit }, jwtToken) {
      commit("refreshToken", jwtToken);
    },

    verify({ commit }, token) {
      return AuthService.verify(token).then(
        (response) => {
          commit("verificationSuccess");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("verificationFailure");
          return Promise.reject(error);
        },
      );
    },

    getUserDetails({ commit }, userId) {
      return AuthService.getUserDetails(userId).then(
        (user) => {
          commit("userFound", user);
          return Promise.resolve(user);
        },
        (error) => {
          return Promise.reject(error);
        },
      );
    },

    getUserById({ commit }, userId) {
      commit("getUserById", userId);
    },
  },

  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    userFound(state, user) {
      state.foundUser = user;
    },
    async getUserById(state, userId) {
      console.log("userID", userId);
      try {
        const response = await authApi(`/User/${userId}`);
        console.log("resonse  user id", response);
        if (response.status === 200) {
          state.currentUser = await  response.data;

console.log('response data in get user by id',response.data)
          return await response.data
        }
      } catch (err) {
        console.log(err);
      }
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;

      localStorage.removeItem("user");
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    },
    updateSuccess(state) {
      state.status.loggedIn = true;
    },
    uploadSuccess(state) {
      state.status.docsUploaded = true;
    },
    uploadFailure(state) {
      state.status.docsUploaded = false;
    },
    updateFailure(state) {
      state.status.loggedIn = true;
    },
    async refreshToken(state, jwtToken) {
      console.log("refresh token mutations", jwtToken);

      try {
        const response = await authApi("/User/refresh-token");
        console.log("resobse refreshToken", response);
        if (response.status === 200) {
          localStorage.setItem("user", JSON.stringify(response.data));
          setTimeout(() => {
            window.location.reload();
          }, 700);
        }
      } catch (err) {
        console.log(err);
      }
      // state.status.loggedIn = true;
      // state.user = { ...state.user, jwtToken: jwtToken };
    },
  },
};
